import { PropType as MiniCartPropType } from 'Organisms/MiniCart/MiniCart';
import { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { styled } from 'stitches.config';
import loadable, { LoadableComponent } from '@loadable/component';
import HeaderTop from './HeaderTop/HeaderTop';
import menuReducer from '../Reducers/menuReducer';
import favoritesReducer from '../Reducers/favoritesReducer';
import HeaderBottom from './HeaderBottom/HeaderBottom';
import useMountTransition from 'Shared/Hooks/useMountTransition';
import {
  PropType as AccountModalPropType,
  ModalName,
} from 'Organisms/AccountModal/AccountModal';
import {
  NO_TOKEN,
  parseTokenFromQuery,
} from '../../../Shared/Common/UrlParser';
import { updateUrl } from '../../../Shared/Common/Helpers';
import NavigationOverlay from '../NavigationMenu/NavigationOverlay.tsx/NavigationOverlay';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { PropType as FavoritesPropType } from 'Shared/Favorites/FavoritesModal';
import { animation, timings } from 'Theme/Settings/animation';
import { useStickyHeaderData } from 'Shared/Providers/StickyHeaderProvider';

const AccountModal: LoadableComponent<AccountModalPropType> = loadable(
  () => import('Organisms/AccountModal/AccountModal')
);

const MiniCart: LoadableComponent<MiniCartPropType> = loadable(
  () => import('Organisms/MiniCart/MiniCart')
);
const FavoritesModal: LoadableComponent<FavoritesPropType> = loadable(
  () => import('Shared/Favorites/FavoritesModal')
);

function DesktopHeader() {
  const [tokenInfo, setTokenInfo] = useState(parseTokenFromQuery());
  const {
    accountDispatch,
    account,
    miniCartDispatch,
    miniCartIsOpen,
    mountMiniCart,
  } = useUserStateData();

  const [{ menuIsOpen, menuId }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
    menuId: null,
  });

  const [{ favoritesIsOpen, mountFavorites }, favoritesDispatch] = useReducer(
    favoritesReducer,
    {
      favoritesIsOpen: false,
      mountFavorites: true,
    }
  );

  const miniCartBtnRef = useRef<HTMLButtonElement>(null);
  const userMenuBtnRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  const clearTokens = useCallback(() => {
    updateUrl(document.title, window.location.pathname);
    setTokenInfo(NO_TOKEN);
  }, [setTokenInfo]);

  const toggle = useCallback(
    () => accountDispatch({ type: 'toggle' }),
    [accountDispatch]
  );

  useEffect(() => {
    if (tokenInfo.type !== null) {
      accountDispatch({ type: 'toggle' });
    }
  }, [tokenInfo, accountDispatch]);

  const hasTransitionedIn = useMountTransition(menuIsOpen || searchIsOpen, 250);

  const { isHideHeader, shouldAnimateSticky } = useStickyHeaderData();

  return (
    <>
      <Placeholder />
      <HeaderTag
        showTransition={isHideHeader}
        sticky={shouldAnimateSticky}
        ref={menuRef}
        id="site-header"
      >
        <HeaderTop
          searchIsOpen={searchIsOpen}
          setSearchIsOpen={setSearchIsOpen}
          menuRef={menuRef}
        />
        <HeaderBottom
          toggleFavorites={() => favoritesDispatch('mountAndOpen')}
          toggleMenu={(id: number) =>
            menuDispatch({ id, type: 'mountAndOpen' })
          }
          onMenuClose={() => menuDispatch({ type: 'closeMenu' })}
          activeMenuId={menuId}
          openMiniCart={() =>
            miniCartDispatch(miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen')
          }
          openUserMenu={() => accountDispatch({ type: 'toggle' })}
          menuIsOpen={menuIsOpen}
          miniCartBtnRef={miniCartBtnRef}
          userMenuBtnRef={userMenuBtnRef}
          menuRef={menuRef}
        />

        <AccountModal
          showModal={account.userMenuIsOpen}
          toggle={toggle}
          clearTokens={clearTokens}
          initialModalState={
            tokenInfo.type === 'emailConfirmed' || tokenInfo.type === 'reset'
              ? {
                  email: tokenInfo.email,
                  token: tokenInfo.token,
                  name:
                    tokenInfo.type === 'emailConfirmed'
                      ? ModalName.CONFIRM
                      : ModalName.RESET,
                }
              : {
                  name: ModalName.LOGIN,
                }
          }
        />
        {mountMiniCart && (
          <MiniCart
            isOpen={miniCartIsOpen}
            closeMiniCart={() => miniCartDispatch('closeMiniCart')}
          />
        )}
        {mountFavorites && (
          <FavoritesModal
            isOpen={favoritesIsOpen}
            closeFavorites={() => {
              favoritesDispatch('close');
            }}
          />
        )}
      </HeaderTag>
      {(hasTransitionedIn || menuIsOpen || searchIsOpen || favoritesIsOpen) && (
        <NavigationOverlay
          isVisible={(hasTransitionedIn && menuIsOpen) || searchIsOpen}
        />
      )}
    </>
  );
}

const HeaderTag = styled('header', {
  zIndex: '$Header',
  backgroundColor: '$siteHeaderDesktopBackground',
  color: '$siteHeaderLinkColor',
  t: 0,
  position: 'relative',
  width: '100%',
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  border: 'none',
  left: 0,
  variants: {
    showTransition: {
      // animate down
      true: {
        t: -16,
      },
      // animate up
      false: {
        t: -32,
        position: 'sticky', //  set sticky again to animate up
      },
    },
    sticky: {
      true: {
        position: 'sticky',
        top: 0,
      },
    },
  },
});

const Placeholder = styled('div', {
  w: '100%',
  minH: '$sizes$desktopHeaderHeight',
  position: 'absolute',
});

export default DesktopHeader;
