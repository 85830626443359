import IHeaderLink from '../../../Models/Headers/HeaderLinkModel.interface';
import { useEffect, useMemo } from 'react';
import { canUseDOM } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import MegaMenuItem from './MenuItem/DesktopMenuItem';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { useHeaderData } from '../Header';

type PropTypes = {
  isOpen: boolean;
  onMenuClose: () => void;
  menuId?: number | null;
  menuRef: React.RefObject<HTMLDivElement>;
};

const MAX_ITEMS_NR = 6;

function DesktopNavigationMenu({ isOpen, onMenuClose, menuId }: PropTypes) {
  const { commerceLinks } = useHeaderData();

  const commerceMegaMenuLinks = [...commerceLinks].find(
    (item) => item?.id === menuId
  );

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    onMenuClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const hasNoSubLinks = useMemo(() => {
    return commerceMegaMenuLinks?.subLinks?.some(
      (link) => link.subLinks.length !== 0
    );
  }, [commerceMegaMenuLinks]);

  if (!commerceMegaMenuLinks?.subLinks) return <></>;

  return (
    <>
      <Wrapper isOpen={isOpen}>
        <ContentContainer width="content" css={{ overflow: 'hidden' }}>
          {isOpen && (
            <List hasNoSubLinks={hasNoSubLinks}>
              {commerceMegaMenuLinks &&
                commerceMegaMenuLinks?.subLinks?.map((item: IHeaderLink) => (
                  <MegaMenuItem
                    key={item?.id}
                    item={item}
                    css={{
                      width: `calc((100% / ${MAX_ITEMS_NR}) - 32px))`,
                    }}
                  />
                ))}
            </List>
          )}
        </ContentContainer>
      </Wrapper>
    </>
  );
}

export default DesktopNavigationMenu;

const Wrapper = styled('div', {
  backgroundColor: '$primary6',
  overflowY: 'auto',
  zIndex: '$zIndices$Header',
  position: 'relative',
  variants: {
    isOpen: {
      true: {
        minHeight: 255,
      },
    },
  },
});

const List = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  my: 8,
  gap: '32px',
  variants: {
    hasNoSubLinks: {
      true: {
        justifyContent: 'space-between',
      },
    },
  },
});
