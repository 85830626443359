import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';
import { useKexNavigate } from '../../../../Kex/KexRouter/KexRouter';
import IHeaderLink from '../../../../Models/Headers/HeaderLinkModel.interface';
import NavigationImageLink from '../NavigationImageLink/NavigationImageLink';
import NavigationIconLink from '../NavigationIconLink/NavigationIconLink';

type PropType = {
  item: IHeaderLink;
  css?: CSS;
};

function DesktopMenuItem({ item, css }: PropType) {
  const kexNavigate = useKexNavigate();

  const goToLink = () => {
    kexNavigate(item?.href);
  };

  const hasNoSubLinks = item?.subLinks?.length === 0;

  return (
    <ItemWrapper css={css} hasNoSubLinks={hasNoSubLinks}>
      {hasNoSubLinks ? (
        <InnerWrapper onClick={goToLink}>
          <NavigationImageLink
            text={item?.text}
            imageHref={
              item?.navigationImage?.src +
              '?preset=mega-menu-item-desktop-large'
            }
            href={item?.href}
            large
          />
        </InnerWrapper>
      ) : (
        <InnerWrapper>
          <NavigationImageLink
            text={item?.text}
            imageHref={
              item?.navigationImage?.src +
              '?preset=mega-menu-item-desktop-small'
            }
            href={item?.href}
          />
          <NavigationSubLinksWrapper>
            {item?.subLinks?.map((subItem: IHeaderLink) => (
              <NavigationIconLink
                key={subItem?.id}
                image={subItem?.navigationImage}
                text={subItem?.text}
                href={subItem?.href}
                iteration={subItem.iteration}
                id={subItem.id}
              />
            ))}
          </NavigationSubLinksWrapper>
        </InnerWrapper>
      )}
    </ItemWrapper>
  );
}

export default DesktopMenuItem;

const InnerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ItemWrapper = styled('div', {
  display: 'block',
  position: 'relative',
  cursor: 'pointer',
  maxW: '48%',
  variants: {
    hasNoSubLinks: {
      false: { flexBasis: '48%' },
    },
  },
});

const NavigationSubLinksWrapper = styled('div', {
  mt: 6,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  flexWrap: 'wrap',
  columnGap: '32px',
  '@mediaMinGreat': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});
