import { styled } from 'stitches.config';
import IHeaderLink from '../../../../Models/Headers/HeaderLinkModel.interface';
import { debounce } from 'Shared/Common/debounce';
import KexLink from 'Kex/KexLink';
import { animateUnderlineActive } from 'Shared/Common/animateUnderline';
import CategoryPageModel from 'Models/Pages/CategoryPage/CategoryPageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';

type PropTypes = {
  toggleMenu: (id: number) => void;
  item: IHeaderLink;
  activeMenuId: number | null;
  menuIsOpen: boolean;
  onMenuClose: () => void;
};

function HeaderLink({
  toggleMenu,
  item,
  activeMenuId,
  menuIsOpen,
  onMenuClose,
}: PropTypes) {
  const openMenu = (id: number) => {
    const delayMS = menuIsOpen ? 0 : 100;
    debounce(() => toggleMenu(id), delayMS);
  };
  const abort = (id: number) => {
    debounce(() => toggleMenu(id), 0, true);
  };

  const navigateToLink = () => {
    onMenuClose();
  };

  const { topMostParentCategoryId } = useCurrentPage<CategoryPageModel>();
  const { pageId } = useCurrentPage<PageModelBase>();
  const selected = activeMenuId === item.id;

  return (
    <>
      <LinkWrapper
        key={item.id}
        onMouseEnter={() => openMenu(item.id)}
        onMouseLeave={() => abort(item.id)}
        onClick={navigateToLink}
        href={item.href}
        clearQueryString={true}
        underlined={false}
      >
        <Text
          selected={
            selected ||
            topMostParentCategoryId === item.id ||
            pageId === item.id
          }
        >
          {item.text}
        </Text>
      </LinkWrapper>
    </>
  );
}

const LinkWrapper = styled(KexLink, {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 2,
  my: 'auto',
  color: '$textPrimary',
  fontFamily: '$fontSecondary500',
  fs: 7,
  lh: '$lh171',
  cursor: 'pointer',
  '&:not(:last-child)': {
    mr: 16,
  },
});

const Text = styled('span', {
  color: '$textPrimary',
  fontFamily: '$fontSecondary500',
  fs: 7,
  lh: '$lh171',
  display: 'inline-block',
  position: 'relative',
  '&:after': {
    transform: 'scaleX(0)',
    content: ' ',
    transition: 'transform 250ms ease-in-out',
    display: 'block',
    borderBottom: '3px solid $JE68GreenPrimary',
    transformOrigin: '0% 0%',
  },
  variants: {
    selected: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

export default HeaderLink;
