import { LogoIcon } from 'Atoms/Icons';
import KexLink from 'Kex/KexLink';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import DesktopQuickSearch from 'Organisms/QuickSearch/DesktopQuickSearch/DesktopQuickSearch';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'stitches.config';
import { useHeaderData } from '../../Header';

type PropTypes = {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  menuRef: React.RefObject<HTMLDivElement>;
};

const HeaderTop = ({ searchIsOpen, setSearchIsOpen, menuRef }: PropTypes) => {
  const { languageRoute } = useAppSettingsData();
  const { topLinks } = useHeaderData();
  return (
    <Wrapper>
      <ContentContainer width="content">
        <Container>
          <Flex>
            <StyledKexLink
              href={`/${languageRoute}`}
              underlined={false}
              aria-label={'home button'}
            >
              <LogoIcon size="headerDesktop" fillColor="white" />
            </StyledKexLink>
          </Flex>
          <DesktopQuickSearch
            searchIsOpen={searchIsOpen}
            setSearchIsOpen={setSearchIsOpen}
            menuRef={menuRef}
          />
          <Right>
            {topLinks.map((topLink, index) => {
              return (
                <StyledKexLink key={topLink.href + index} href={topLink.href}>
                  {topLink.text}
                </StyledKexLink>
              );
            })}
          </Right>
        </Container>
      </ContentContainer>
    </Wrapper>
  );
};

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

const Wrapper = styled('div', {
  position: 'relative',
  zIndex: '$Header',
  height: '72px',
  display: 'flex',
  alignItems: 'center',
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Right = styled('div', {
  color: '$textPrimary',
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '32px',
  justifyContent: 'end',
  ml: 4,
  flex: 1,
});

const StyledKexLink = styled(KexLink, {
  mb: 0,
  whiteSpace: 'nowrap',
  fs: 7,
  color: '$textPrimary',
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh171',
  wordSpacing: 'unset',
  ls: 'unset',
});

export default HeaderTop;
