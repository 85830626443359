import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'Shared/Common/debounce';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import {
  useKexNavigate,
  useKexRouterCurrentPage,
} from '../../../Kex/KexRouter/KexRouter';
import SearchInput from '../SearchInput';
import { QuickSearch } from '../../../Pages/SearchPage/Search';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import IconLink from 'Atoms/Links/IconLink';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { H6 } from 'Atoms/Typography/Headings/Heading';
import SearchPhraseResult from '../../../Models/Search/SearchPhraseResult.interface';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { LoadingCircle } from 'Atoms/Icons';
import { styled } from 'stitches.config';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import SearchCard from 'Organisms/ProductCard/SearchCard';
import { canUseDOM, getSearchQueryParameter } from 'Shared/Common/Helpers';
import { useUserStateData } from '../../../Shared/Providers/UserContextProvider';

type PropTypes = {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  menuRef: React.RefObject<HTMLDivElement>;
};

function DesktopQuickSearch({
  searchIsOpen,
  setSearchIsOpen,
  menuRef,
}: PropTypes) {
  const {
    staticPages: { searchPage },
    languageRoute,
  } = useAppSettingsData();

  const {
    account: { query: searchedQuery },
    accountDispatch,
  } = useUserStateData();

  const page = useKexRouterCurrentPage();
  const onSearchPage = page.pageType === 'SearchPage';

  const {
    searchLabels: {
      quickSearchCategories,
      quickSearchPages,
      quickSearchProducts,
      showAllProducts,
    },
  } = useTranslationData();

  const kexNavigate = useKexNavigate();
  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<SearchPhraseResult>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setQuery(searchedQuery), [searchedQuery]);

  const hits =
    result &&
    result?.categoryCollection?.availableItems +
      result?.contentCollection?.availableItems +
      result?.productCollection?.availableItems;

  useOutsideClick(menuRef, () => {
    searchIsOpen && setSearchIsOpen(false);
  });

  const doSearch = () => {
    if (query.length > 1) {
      setTimeout(() => {
        setSearchIsOpen(false);
      }, 400);

      accountDispatch({ type: 'setQuery', query });
      if (!onSearchPage) {
        kexNavigate(`${searchPage}?query=${query}`);
      }
    }
  };

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    setTimeout(() => {
      setSearchIsOpen(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const toggleSearch = () => {
    setSearchIsOpen(!searchIsOpen);
  };

  const doQuickSearch = useCallback(async () => {
    const quickSearchQuery = query;
    setIsLoading(true);
    const res = await QuickSearch(
      searchPage,
      quickSearchQuery,
      languageRoute,
      setIsLoading
    );

    // user navigated
    if (getSearchQueryParameter() === query || query === searchedQuery) {
      return;
    }

    res && setResult(res);
    if (
      res &&
      res?.categoryCollection?.availableItems +
        res?.contentCollection?.availableItems +
        res?.productCollection?.availableItems >
        0
    ) {
      setSearchIsOpen(true);
    }
  }, [languageRoute, query, searchPage, setSearchIsOpen, searchedQuery]);

  useEffect(() => {
    debounce(() => {
      if (query.length >= 3) {
        doQuickSearch();
      } else {
        setSearchIsOpen(false);
      }
    }, 300);
  }, [query, searchPage, doQuickSearch, setSearchIsOpen]);

  return (
    <>
      {searchIsOpen && (
        <QuickSearchWrapper>
          <ContentContainer>
            {hits && hits > 0 ? (
              <Grid>
                <Column>
                  {result?.contentCollection &&
                    result.contentCollection.availableItems > 0 && (
                      <HeaderWrapper>
                        <H6>{`${quickSearchPages} (${result.contentCollection.availableItems})`}</H6>
                      </HeaderWrapper>
                    )}
                  {result?.contentCollection?.items.map((item: any) => (
                    <IconLink
                      type="Arrow"
                      href={item.url}
                      key={item.id}
                      onClick={() => setSearchIsOpen(false)}
                    >
                      {item.name}
                    </IconLink>
                  ))}
                </Column>
                <Column>
                  {result?.categoryCollection &&
                    result.categoryCollection.availableItems > 0 && (
                      <HeaderWrapper>
                        <H6>{`${quickSearchCategories} (${result.categoryCollection.availableItems})`}</H6>
                      </HeaderWrapper>
                    )}
                  {result?.categoryCollection?.items.map((item: any) => (
                    <IconLink
                      type="Arrow"
                      href={item.url}
                      key={item.id}
                      onClick={() => setSearchIsOpen(false)}
                    >
                      {item.name}
                    </IconLink>
                  ))}
                </Column>
                <Column>
                  {result?.productCollection &&
                    result.productCollection.availableItems > 0 && (
                      <>
                        <HeaderWrapper>
                          <H6>{`${quickSearchProducts} (${result.productCollection.availableItems})`}</H6>
                        </HeaderWrapper>

                        {result?.productCollection?.items.map(
                          (item: SearchLinkResultModel, index: number) => {
                            if (index < 3) {
                              return (
                                <SearchCard
                                  key={item.id + index}
                                  item={item}
                                  onClick={toggleSearch}
                                />
                              );
                            } else
                              return <React.Fragment key={item.id + index} />;
                          }
                        )}
                        <LinkWrapper>
                          <StyledIconLink
                            onClick={toggleSearch}
                            type="Arrow"
                            href={`${searchPage}?query=${query}`}
                          >
                            {showAllProducts}
                          </StyledIconLink>
                        </LinkWrapper>
                      </>
                    )}
                </Column>
              </Grid>
            ) : (
              <>
                {isLoading && (
                  <>
                    <LoadingCircle
                      isLoading={isLoading}
                      color="primary"
                      onBackground
                    />
                  </>
                )}
              </>
            )}
          </ContentContainer>
        </QuickSearchWrapper>
      )}
      <SearchInput
        doSearch={doSearch}
        query={query}
        setQuery={setQuery}
        inputRef={inputRef}
      />
    </>
  );
}

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const HeaderWrapper = styled('div', {
  mb: 3,
});

const LinkWrapper = styled('div', {
  ml: 'auto',
});

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 2fr',
});

const StyledIconLink = styled(IconLink, {
  my: 2,
  ml: 'auto',
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh15',
});

const QuickSearchWrapper = styled('div', {
  py: 8,
  height: 'auto',
  color: 'white',
  w: '100%',
  t: '$sizes$desktopHeaderUpperHeight',
  l: 0,
  backgroundColor: '$backgroundPrimary',
  position: 'absolute',
  zIndex: '$Header',
});

export default React.memo(DesktopQuickSearch);
