import { styled } from 'stitches.config';
import { useHeaderData } from '../../Header';
import IHeaderLink from '../../../../Models/Headers/HeaderLinkModel.interface';
import HeaderLink from './HeaderLink';
import { CartIcon, HeartIcon, UserIcon } from 'Atoms/Icons';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import CartNotifier from 'Organisms/Header/CartNotifier/CartNotifier';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import KexLink from 'Kex/KexLink';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import DesktopNavigationMenu from 'Organisms/Header/NavigationMenu/DesktopNavigationMenu';
import { GetFavorites } from 'Shared/Favorites/Favorites';

type PropTypes = {
  toggleFavorites: () => void;
  toggleMenu: (id: number) => void;
  openMiniCart: () => void;
  openUserMenu: () => void;
  activeMenuId: number | null;
  menuIsOpen: boolean;
  miniCartBtnRef: React.RefObject<HTMLButtonElement>;
  userMenuBtnRef: React.RefObject<HTMLButtonElement>;
  onMenuClose: () => void;
  menuRef: React.RefObject<HTMLDivElement>;
};
function HeaderBottom({
  toggleMenu,
  activeMenuId,
  openMiniCart,
  menuIsOpen,
  openUserMenu,
  onMenuClose,
  toggleFavorites,
  menuRef,
}: PropTypes) {
  const { commerceLinks } = useHeaderData();
  const {
    user: { authenticated },
  } = useUserStateData();

  const {
    staticPages: { accountPage },
    languageRoute,
  } = useAppSettingsData();

  const { hasFavorites } = GetFavorites(languageRoute);
  return (
    <Container onMouseLeave={() => menuIsOpen && onMenuClose()}>
      <ContentContainer width="content">
        <HeaderBottomWrapper>
          <Left>
            {commerceLinks &&
              commerceLinks.map((item: IHeaderLink, index) => (
                <HeaderLink
                  key={item.id}
                  item={item}
                  toggleMenu={toggleMenu}
                  activeMenuId={activeMenuId}
                  menuIsOpen={menuIsOpen}
                  onMenuClose={onMenuClose}
                />
              ))}
          </Left>
          <Right>
            {authenticated ? (
              <ButtonWrapper aria-label={'account button'}>
                <KexLink
                  css={{ m: 0 }}
                  href={accountPage}
                  underlined={false}
                  aria-label={'account link'}
                >
                  <UserIcon color="primary" size="m" />
                </KexLink>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper
                aria-label={'account button'}
                role={'button'}
                onClick={openUserMenu}
              >
                <UserIcon color="secondary" size="m" />
              </ButtonWrapper>
            )}

            <ButtonWrapper
              aria-label={'favorites button'}
              id={'favorites button'}
              onClick={() =>
                authenticated ? toggleFavorites() : openUserMenu()
              }
            >
              <HeartIcon
                color={authenticated && hasFavorites ? 'primary' : 'secondary'}
                size="m"
              />
            </ButtonWrapper>
            <ButtonWrapper aria-label={'cart button'} onClick={openMiniCart}>
              <CartIcon color="secondary" size="m" />
              <CartNotifier />
            </ButtonWrapper>
          </Right>
        </HeaderBottomWrapper>
      </ContentContainer>

      <DesktopNavigationMenu
        isOpen={menuIsOpen}
        onMenuClose={onMenuClose}
        menuId={activeMenuId}
        menuRef={menuRef}
      />
    </Container>
  );
}
const Container = styled('div', {
  backgroundColor: '$siteHeaderDesktopBackgroundLower',
  mh: 18.5,
  h: 14,
  position: 'relative',
});

const ButtonWrapper = styled('button', {
  position: 'relative',
  '&:not(:last-child)': {
    mr: 8,
  },
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  cursor: 'pointer',
});

const Left = styled('div', {
  display: 'flex',
  alignItems: 'start',
});

const Right = styled('div', {
  display: 'flex',
  alignItems: 'end',
});

const HeaderBottomWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  py: 4,
});

export default HeaderBottom;
